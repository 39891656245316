import React, { FC, useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';
import { Layout, Seo } from '@components/core';
import { FaqHeader, FaqMenu, Title, ToTop } from '@components/ui';
import { colorPalette } from '@shared/theme';

interface IFaqPageProps {
	location: {
		search: string;
		state: {
			postcode: string;
		};
	};
}

const WrapperStyled = styled.div`
	display: flex;
	margin: 0 auto;
	padding: 100px 85px;

	@media screen and (max-width: 992px) {
		padding: 50px 15px;
	}
`;

const InfoSection = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 60px;

	@media screen and (max-width: 992px) {
		margin-left: 0;

		h2 {
			padding: 0 !important;
			margin: 0;
		}
	}
`;

const SubtitleStyled = styled.p`
	font-size: 19px;
	font-weight: 500;
	line-height: 1.47;
	padding: 36px 0;
	margin: 0;
	color: ${colorPalette.dimGray};
`;

const LinkStyled = styled.a`
	color: ${colorPalette.blue};
	font-weight: 500;
	line-height: 1.2;
	margin: 0;
	padding: 0;
	cursor: pointer;
`;

const ListStyled = styled.ul`
	padding: 0;
	margin-left: 20px;
`;

const ListItemStyled = styled.li`
	margin: 0 0 12px;
`;

const SectionTitleStyled = styled.h3`
	font-size: 22px;
	font-weight: bold;
	line-height: 1.5;
	margin: 0;
	padding: 60px 0 24px 0;
	color: ${colorPalette.dimGray};
`;

const TitleStyled = styled.p`
	font-size: 19px;
	margin: 0;
	padding: 0;
	font-weight: bold;
	line-height: 1.47;
	color: ${colorPalette.dimGray};
`;

const TextStyled = styled.p`
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
	padding: 0;
	margin: 0;
	color: ${colorPalette.lightGray};
`;

const TextTitleStyled = styled.p`
	font-size: 19px;
	font-weight: bold;
	line-height: 1.47;
	color: ${colorPalette.dimGray};
	padding: 36px 0 0;
	margin: 0;
`;

const FaqPage: FC<IFaqPageProps> = ({ location }) => {
	if (typeof window === 'undefined' || !window.document) return null;

	const [activeItem, setActiveItem] = useState<string>();
	const [postcode, setPostcode] = useState<string | null>(
		localStorage.getItem('postcode')
	);
	const [sensorActive, setSensorActive] = useState<boolean>(true);

	useEffect(() => {
		const data = location.state?.postcode;

		if (data) {
			setPostcode(data);
			localStorage.setItem('postcode', data);
		}
	}, []);

	function onMenuItemActivated(isActive: boolean) {
		setSensorActive(!isActive);
	}

	return (
		<Layout>
			<Seo title="FAQ" />
			<FaqHeader postcode={postcode} />
			<WrapperStyled>
				<ToTop />
				<FaqMenu activeItem={activeItem} activated={onMenuItemActivated} />
				<InfoSection>
					<Title>Bekijk de antwoorden op de meest gestelde vragen</Title>
					<SubtitleStyled>
						Hieronder vindt u veelgestelde vragen over Klimaat en U. Staat uw
						vraag er niet tussen? Laat het ons dan weten via{' '}
						<LinkStyled href="mailto:contact@esri.nl">
							contact@esri.nl
						</LinkStyled>
					</SubtitleStyled>
					<ListStyled>
						<ListItemStyled>
							<LinkStyled
								href="#HoekomtEsriaandezedata"
								onClick={() => setActiveItem('#faqEsri')}
							>
								Hoe komt Esri aan deze data?
							</LinkStyled>
						</ListItemStyled>
						<ListItemStyled>
							<LinkStyled
								href="#Watisdenauwkeurigheidenhetschaalniveauvandegegevens"
								onClick={() => setActiveItem('#faqData')}
							>
								Wat is de nauwkeurigheid en het schaalniveau van de gegevens?
							</LinkStyled>
						</ListItemStyled>
						<ListItemStyled>
							<LinkStyled
								href="#Waarkomendegegevensenkaartenvandaan"
								onClick={() => setActiveItem('#faqData')}
							>
								Waar komen de gegevens en kaarten vandaan?
							</LinkStyled>
						</ListItemStyled>
						<ListItemStyled>
							<LinkStyled
								href="#Welkeandere(kaart)gegevensheeftEsrinogmeerbeschikbaar"
								onClick={() => setActiveItem('#faqData')}
							>
								Welke andere (kaart) gegevens heeft Esri nog meer beschikbaar?
							</LinkStyled>
						</ListItemStyled>
						<ListItemStyled>
							<LinkStyled
								href="#Watzijnklimaatscenarios"
								onClick={() => setActiveItem('#faqKlimaat')}
							>
								Wat zijn klimaatscenario’s?
							</LinkStyled>
						</ListItemStyled>
						<ListItemStyled>
							<LinkStyled
								href="#Hoekomikincontactmetdemakersvandezepagina"
								onClick={() => setActiveItem('#faqOverig')}
							>
								Hoe kom ik in contact met de makers van deze pagina?
							</LinkStyled>
						</ListItemStyled>
					</ListStyled>

					<VisibilitySensor
						onChange={(vis) => (vis ? setActiveItem('#faqEsri') : '')}
						active={sensorActive}
					>
						<>
							<SectionTitleStyled id="faqEsri">Esri</SectionTitleStyled>
							<TitleStyled>Wie is Esri?</TitleStyled>
							<TextStyled>
								Een kaart verbindt mensen, waar ze ook zijn. Door te werken met
								dezelfde kaart heeft iedereen hetzelfde beeld. Dankzij het
								inzicht dat ArcGIS geeft via kaarten en geografische analyses,
								is het mogelijk een verschil te maken. Wereldwijd werken meer
								dan 350.000 organisaties dagelijks met de innovatieve
								technologie van Esri. In Nederland bestaat het brede netwerk uit
								meer dan 1.500 Nederlandse organisaties, waaronder grote steden,
								overheden en het bedrijfsleven. Deze organisaties onthullen de
								kracht van hun data en maken zichzelf slimmer met een
								geografisch informatiesysteem (GIS).
							</TextStyled>
							<TextTitleStyled id="WelkeorganisatieswerkenermetEsri?">
								Welke organisaties werken er met Esri?
							</TextTitleStyled>
							<TextStyled>
								Wereldwijd werken er meer dan 350.000 organisaties met de
								technologie van Esri. Van centrale overheid tot lokale overheid
								en een groot deel van de Fortune 500-bedrijven. In Nederland
								werken gemeenten, provincies, waterschappen, utilities,
								mainports en nog vele bedrijven uit andere sectoren met
								Esri-technologie.
							</TextStyled>
							<TextTitleStyled id="HoekomtEsriaandezedata">
								Hoe komt Esri aan deze data?
							</TextTitleStyled>
							<TextStyled>
								Esri maakt gebruik van bestaande, betrouwbare Nederlandse
								databronnen. Dit kan data zijn die als basisregistratie door de
								overheid beschikbaar wordt gesteld, of data van verschillende
								toonaangevende data-partijen. Voor deze website is gebruik
								gemaakt van data verzameld door verschillende kennisinstellingen
								en beschikbaar gesteld door Climate Adaptation Services via{' '}
								<LinkStyled
									href="https://klimaateffectatlas.nl/nl/"
									target="_blank"
								>
									klimaateffectatlas.nl
								</LinkStyled>
							</TextStyled>
						</>
					</VisibilitySensor>

					<VisibilitySensor
						onChange={(vis) => (vis ? setActiveItem('#faqData') : '')}
						offset={{ top: 100 }}
						partialVisibility={true}
						minTopValue={500}
						active={sensorActive}
					>
						<>
							<SectionTitleStyled id="faqData">Data</SectionTitleStyled>
							<TitleStyled id="Watisdenauwkeurigheidenhetschaalniveauvandegegevens">
								Wat is de nauwkeurigheid en het schaalniveau van de gegevens?
							</TitleStyled>
							<TextStyled>
								De gegevens zijn gebaseerd op landelijke modellen: de resultaten
								geven een beeld op regionaal schaalniveau en zijn meestal grof
								en indicatief op lokaal niveau. Op landelijk niveau beogen de
								kaarten de best beschikbare gegevens weer te geven. Het
								schaalniveau van de gegevens verschilt per kaart. De
								klimaatkaarten zoals het aantal tropische dagen of
								neerslagtekort geven een beeld van verschillen op landelijk
								niveau. Andere kaarten, zoals maximale overstromingsdiepte geven
								meer detail.
							</TextStyled>
							<TextTitleStyled id="Hoeisditteverklaren">
								De uitkomst voor mijn postcodes is identiek, of wijkt sterk af,
								van die van een nabijgelegen postcode. Hoe is dit te verklaren?
							</TextTitleStyled>
							<TextStyled>
								Het detailniveau van de gegevens verschilt sterk per kaart.
								Klimaatkaarten zoals het aantal tropische dagen of
								neerslagtekort geven een beeld van verschillen op landelijk
								niveau. Er zijn aanzienlijke regionale verschillen in Nederland
								binnen het voor deze website gebruikte klimaatscenario. De
								gegevens zijn echter veelal dus grof en daarmee is de kans reëel
								dat postcodes die geografisch dicht bij elkaar liggen dezelfde
								uitkomst geven. Andere gegevens kennen meer detail, zoals
								maximale overstromingsdiepte. De kaart van maximale
								overstromingsdiepte is gebaseerd op blokken van 100 bij 100
								meter. In een blok is een gemiddelde maaiveldhoogte aangenomen
								en wordt de maximale overstromingsdiepte voor dat blok
								vastgesteld. Voor het tonen van de gegevens op de website wordt
								het midden van een postcodegebied aangehouden en de waarde
								overgenomen van het blok waarbinnen dit postcodepunt valt.
							</TextStyled>
							<TextTitleStyled id="Waarkomendegegevensenkaartenvandaan">
								Waar komen de gegevens en kaarten vandaan?
							</TextTitleStyled>
							<TextStyled>
								De data die gebruikt is voor deze website komt van de
								klimaateffectatlas, welke in beheer is bij Climate Adaption
								Services. De kaarten in de atlas zijn door verschillende
								kennisinstellingen ontwikkeld. Hierbij is rekening gehouden met
								de KNMI’14 scenario’s. De kaarten worden als open data
								beschikbaar gemaakt. Het data-team van Esri Nederland stelt alle
								kaarten uit de klimaateffectatlas beschikbaar als webservices om
								deze nog breder deelbaar en toegankelijk te maken voor
								visualisatie en analyse doeleinden. De specialisatie van dit
								team van Esri Nederland ligt bij het beschikbaar stellen en
								onderhouden van honderden landsdekkende Nederlandse datasets die
								allemaal beschikbaar zijn in het geografische informatiesysteem
								van Esri: het ArcGIS-platform.
							</TextStyled>
							<TextTitleStyled id="Welkeandere(kaart)gegevensheeftEsrinogmeerbeschikbaar">
								Welke andere (kaart) gegevens heeft Esri nog meer beschikbaar?
							</TextTitleStyled>
							<TextStyled>
								Esri biedt een grote hoeveelheid aan kaartlagen aan, zoals
								bijvoorbeeld achtergrondkaarten, basisregistraties,
								demografische gegevens, hoogtegegevens en POI’s. De volledige
								set aan beschikbare data binnen ArcGIS noemen we ook wel de
								Levende Atlas. De Levende Atlas bevat kwalitatieve kaarten
								waarvan het data-team van Esri Nederland de vulling voor
								Nederland verzorgt. Deze Levende Atlas bevat daarnaast ook
								kaarten, apps en data van andere delen van de wereld. Ontdekt
								meer op{' '}
								<LinkStyled href="https://esri.nl/data" target="_blank">
									esri.nl/data
								</LinkStyled>
							</TextStyled>
							<TextTitleStyled id="Kanenmagikdezedataookgebruiken">
								Kan en mag ik deze data ook gebruiken? En zo ja, hoe kan dit?
							</TextTitleStyled>
							<TextStyled>
								Deze data is openbaar beschikbaar gesteld via de
								klimaateffectatlas en mag gebruikt worden. Via ArcGIS kunt u
								deze data visualiseren en analyseren. Nog geen toegang tot
								ArcGIS? Vraag een kosteloze trial aan via{' '}
								<LinkStyled
									href="https://esri.nl/nl-nl/producten/trial"
									target="_blank"
								>
									esri.nl/trial
								</LinkStyled>
							</TextStyled>
						</>
					</VisibilitySensor>

					<VisibilitySensor
						onChange={(vis) => (vis ? setActiveItem('#faqKlimaat') : '')}
						offset={{ top: 200 }}
						partialVisibility={true}
						minTopValue={300}
						active={sensorActive}
					>
						<>
							<SectionTitleStyled id="faqKlimaat">Klimaat</SectionTitleStyled>
							<TitleStyled id="Welkeaannamesoverklimaatveranderingwordengehanteerd">
								Welke aannames over klimaatverandering worden gehanteerd?
							</TitleStyled>
							<TextStyled>
								De website sluit aan op de KNMI&apos;14 scenario&apos;s. Het
								KNMI heeft in 2014 klimaatscenario’s opgesteld die de
								bandbreedte schetsen waarbinnen klimaatverandering in Nederland
								tot 2050 en 2085 waarschijnlijk zal plaatsvinden. Om de
								hoeveelheid informatie overzichtelijk te houden, is ervoor
								gekozen twee beelden met elkaar te vergelijken: huidig klimaat
								en het jaar 2050 onder scenario WH. Dit is voor de meeste
								klimaateffecten het worst-case scenario en geeft daarmee de
								bovenkant van de bandbreedte van verwachtingen weer. Meer
								informatie over de KNMI’14 scenario’s is{' '}
								<LinkStyled href="http://www.klimaatscenarios.nl/brochures/images/Brochure_KNMI14_NL_2015.pdf">
									hier
								</LinkStyled>{' '}
								beschikbaar.
							</TextStyled>

							<TextTitleStyled id="Watzijnklimaatscenarios">
								Wat zijn klimaatscenario’s?
							</TextTitleStyled>
							<TextStyled>
								De klimaatscenario's van het KNMI geven aan welke
								klimaatveranderingen in Nederland in de toekomst plausibel zijn.
								De KNMI'14-klimaatscenario's laten een beeld zien van hogere
								temperaturen, een sneller stijgende zeespiegel, nattere winters,
								hevigere buien en kans op drogere zomers. De
								KNMI'14-klimaatscenario's zijn gebaseerd op de laatste
								wetenschappelijke inzichten. Meer informatie over de KNMI’14
								scenario’s is{' '}
								<LinkStyled href="http://www.klimaatscenarios.nl/brochures/images/Brochure_KNMI14_NL_2015.pdf">
									hier
								</LinkStyled>{' '}
								beschikbaar.
							</TextStyled>
						</>
					</VisibilitySensor>

					<VisibilitySensor
						onChange={(vis) => (vis ? setActiveItem('#faqOverig') : '')}
						offset={{ bottom: 500 }}
						partialVisibility={true}
						minTopValue={300}
						active={sensorActive}
					>
						<>
							<SectionTitleStyled id="faqOverig">Overig</SectionTitleStyled>
							<TitleStyled id="Hoekomikincontactmetdemakersvandezepagina">
								Hoe kom ik in contact met de makers van deze pagina?
							</TitleStyled>
							<TextStyled>
								Via het contactformulier onderaan de website is het mogelijk om
								in contact te komen met het content-team van Esri Nederland. U
								kunt ook een e-mail sturen naar{' '}
								<LinkStyled href="mailto:contact@esri.nl">
									contact@esri.nl
								</LinkStyled>
								.
							</TextStyled>
							<TextTitleStyled>
								Hoe zit het met aansprakelijkheid bij het gebruik van gegevens
								op klimaat.esri.nl?
							</TextTitleStyled>
							<TextStyled>
								De website en de gebruikte kaarten zijn bedoeld om het thema
								klimaatadaptatie op de agenda te krijgen en een gevoel te
								krijgen bij de orde grootte van klimaateffecten. De kaarten zijn
								niet bedoeld voor lokale besluitvorming. De gegevens die
								gebruikt worden zijn afkomstig uit klimaateffectatlas.nl. Esri
								Nederland als maker en beheerder van deze website aanvaard geen
								enkele verantwoordelijkheid voor gevolgen van aannames of
								gevolgtrekkingen gebaseerd op teksten, grafieken of kaarten op
								website.
							</TextStyled>
						</>
					</VisibilitySensor>
				</InfoSection>
			</WrapperStyled>
		</Layout>
	);
};

export default FaqPage;
